.companies-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 15rem;
  width: 100vw;
  margin-bottom: 1rem;
}
.companies-section-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.horiz-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  height: 100%;
  margin-top: 1.5rem;
  width: 100%;
}

.companies-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 5rem;
  width: 14rem;
  -o-object-fit: contain;
  object-fit: contain;
  cursor: pointer;
}

@media (max-width: 768px) {
  .companies-logo {
    height: 2rem;
    width: 4rem;
  }
}
