body {
  font-family: 'Poppins', sans-serif !important;
}

.features-image {
  width: 5vw;
}

#features .feature-column {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.switch {
  position: relative;
  display: flex;
  align-items: center;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: relative;
  cursor: pointer;
  width: 60px;
  height: 34px;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-right: 12px;
  border: 2px solid #ccc;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: #00aeef;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked ~ .slider {
  background-color: transparent;
}

input:focus ~ .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked ~ .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch input ~ .bill--monthly {
  color: #00aeef;
}

.switch label {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
  margin-right: 12px;
  transition: 0.4s;
}

.switch input:checked ~ .bill--monthly {
  color: #212529;
}

.switch input:checked ~ .bill--yearly {
  color: #00aeef;
}

.pricing-plan {
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.plan-wrapper {
  border: 2px solid #f3f3f3;
  padding: 15px;
  flex: 0 0 calc(25% - 15px);
  background-color: #fff;
}

.plan-wrapper h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  min-height: 70px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.plan-wrapper .plan-price {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.plan-wrapper .plan-price .amount {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  margin: 0 4px;
}

.plan-wrapper .plan-price .bill-type {
  font-size: 12px;
  line-height: 13px;
  align-self: center;
  font-weight: 600;
}

.plan-wrapper .c-btn {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  min-width: 200px;
}

.feature-list {
  list-style: none;
}

.feature-list li {
  display: flex;
  margin-bottom: 12px;
}

.feature-list li svg {
  height: 24px;
  display: inline-block;
  margin-right: 12px;
  flex: 0 0 24px;
  fill: #69e859;
}

.feature-list li p {
  line-height: 24px;
  font-size: 14px;
  color: #212529;
  margin-bottom: 0px;
}

.feature-list li p strong {
  color: #a9a9a9;
  font-weight: 400;
}

.plan-wrapper .c-btn:before {
  top: 0px;
}

.section-pricing {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
}

.plan-header {
  min-height: 230px;
  display: flex;
  flex-direction: column;
}

.plan-header .c-btn {
  margin-top: auto;
}

.save-tag {
  color: #00aeef;
}

.billing-option {
  display: flex;
}

.billing-option .switch {
  margin-top: 20px;
}

.feature-list.cf--grouped {
  margin-top: 30px;
}

.feature-list {
  margin-top: 12px;
}

.text-underline {
  text-decoration: underline;
  cursor: pointer;
}

.plan-wrapper h6 {
  margin-top: 25px;
  font-weight: 600;
}

.plan-price-note {
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.cld-contact-info {
  max-width: 350px;
  margin-right: 30px;
}

.cld-hubspot-form {
  flex-grow: 1;
}

.page-payment-result {
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  padding: 190px 0px 0px;
}

.site--loaded .payment {
  opacity: 1;
  transform: scale(1);
}

.payment {
  opacity: 0;
  transform: scale(0);
  position: relative;
  padding: 30px;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.275, 1.155);
}

.payment__check,
.payment__error {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate3d(-50%, -50%, 0);
  fill: #fff;
}

.payment__background {
  fill: #00aeef;
  animation: rotate 15s linear both infinite;
  max-width: initial;
  max-height: inherit;
}

.payment--success .payment__background {
  fill: #4bb543;
}

.payment--error .payment__background {
  fill: #ca0b00;
  animation: none;
}

.section-message .message-container {
  text-align: center;
  max-width: 430px;
}

.section-message .message-container p {
  font-size: 16px;
  line-height: 24px;
  color: #999;
}

button.c-btn {
  border: 0px;
}

.c-btn {
  cursor: pointer;
}

.c-btn .c-btn-text {
  position: relative;
  z-index: 6;
  transition: color 0.45s;
  text-transform: capitalize;
}

.loadersmall {
  border: 3px solid rgba(0, 0, 0, 0.129);
  animation: spin 1s linear infinite;
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.c-btn .loader-wrapper {
  z-index: 99;
  position: absolute;
  width: 26px;
  height: 26px;
  top: calc(50% - 13px);
  left: calc(50% - 13px);
  opacity: 0;
  pointer-events: none;
}

.c-btn.is--loading .loader-wrapper {
  opacity: 1;
}

.c-btn.is--loading .c-btn-text {
  opacity: 0;
}

.form-group label.error {
  background-color: #ffd6d6;
  display: block;
  font-size: 11px;
  padding: 2px 14px;
  border-radius: 0 0 4px 4px;
  line-height: 18px;
  color: #de0f0b;
  position: relative;
  top: -5px;
  z-index: 3;
  max-height: 0;
  overflow: hidden;
  -webkit-animation: showError 1s ease 0s forwards;
  animation: showError 1s ease 0s forwards;
}

.projects-carousel .slick-slide > div {
  padding: 10px;
}

.projects-carousel .slick-slide .project-box-overlay {
  pointer-events: none;
}

.projects-carousel .slick-slide .project-box-overlay:after {
  pointer-events: all;
}

.projects-carousel .slick-dots {
  display: flex !important;
}

.ce-nav-padding {
  padding-top: 88px;
}

.auth-container.register .plan-header > .c-btn {
  display: none;
}

.auth-container.register .plan-header {
  min-height: 160px;
}

.language-selector .dropdown-toggle.btn-primary {
  background: transparent !important;
  padding: 4px;
}

.language-selector .dropdown-menu {
  margin-top: -32px !important;
}

.ce-plan-modal .modal-lg {
  max-width: 1100px;
}

.testimonial-carousel .slick-dots {
  display: flex !important;
}

.c-btn.c-btn-gradient.disabled {
  pointer-events: none;
  background: #999999;
}

a {
  cursor: pointer;
}

.ce-error-wrapper {
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
}

.arrow-btn {
  top: 45%;
  z-index: 1;
}

.next {
  float: right;
}

a.c-btn.c-btn-gradient.slide-up {
  margin-left: 9px;
}

section#about blockquote.slide-up {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.ce-error-wrapper h4 {
  font-size: 14px;
  color: #cf2222;
}

.ce-error-wrapper ul {
  margin: 0;
  padding-left: 18px;
  font-size: 12px;
}

/* .c-btn+.c-btn {
	margin-left: 15px;
} */

.c-btn.c-btn-link {
  background: transparent;
  color: #59b0f4;
  padding: 3px 20px;
  font-size: 14px;
  min-width: auto;
  border-radius: 5px;
}

.c-btn.c-btn-link .loader-wrapper {
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

.c-btn.c-btn-link .loader-wrapper .loadersmall {
  border-top: 3px solid #59b0f4;
}

.c-btn.form-overlayed-btn {
  position: absolute;
  right: 20px;
  z-index: 9;
  top: 5px;
}

.c-btn.c-btn-gradient.c-btn-link:hover {
  transform: none;
}

.c-btn.c-btn-gradient.c-btn-link::before {
  background-color: rgba(88, 175, 242, 0.31);
}

.form-group {
  position: relative;
}

.ce-header {
  padding: 20px 15px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
}

.ce-header h6 {
  margin-bottom: 0px;
}

.ce-body {
  padding: 20px 15px;
}

.ce-wrapper {
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e3e3e3;
}

.ce-body h6 {
  margin-bottom: 15px;
}

.ce-body p {
  margin-bottom: 0px;
}

.overlaying-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fffffff2;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-wrapper.plan--is-selected {
  border: 4px solid #51a1df;
  position: relative;
}

.plan-wrapper.plan--is-selected:before {
  content: 'Selected Plan';
  left: -4px;
  width: calc(100% + 8px);
  height: 45px;
  font-size: 16px;
  color: #fff;
  background-color: #51a1df;
  position: absolute;
  top: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no--style {
  color: inherit;
}

.no--style:hover {
  color: inherit;
}

.c-btn.c-btn-gradient.c-btn-danger {
  background: #a3a3a3;
  background: linear-gradient(to right, #a3a3a3 0%, #818181 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$theme-blue-gradient1', endColorstr='$theme-blue-gradient2', GradientType=1);
}

.c-btn.c-btn-gradient.c-btn-danger:before {
  background-color: rgba(0, 0, 0, 0.4);
}

.plan-header .text-danger {
  font-size: 12px;
  text-align: center;
}

.ce-bd-wrapper {
  margin-left: auto;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.ce-bd-wrapper .badge.badge-pill {
  margin-left: 6px;
  text-transform: capitalize;
  font-size: 12px;
}

/* .projects-carousel-wrapper .projects-carousel .slick-list {
	overflow: hidden;
} */

@keyframes showError {
  0% {
    max-height: 0;
  }

  to {
    max-height: 250px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  0%,
  100% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes checkmark {
  0%,
  100% {
    opacity: 0;
    transform: scale(0);
  }

  10%,
  50%,
  90% {
    opacity: 1;
    transform: scale(1);
  }
}

@media only screen and (min-width: 1200px) {
  #features .feature-column {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }

  #pointer3 {
    margin-top: 1vw;
    margin-bottom: 1.3vw;
  }
}

@media only screen and (max-width: 1200px) {
  .plan-wrapper {
    flex: 0 0 calc(50% - 15px);
    margin-bottom: 30px;
  }
}

@media (max-width: 1199px) {
  #features .feature-column {
    flex: 0 0 33.33%;
    width: 33.33%;
    max-width: 33.33%;
  }

  #pointer3 {
    margin-bottom: 1vw;
  }
}

@media (max-width: 767px) {
  #features .feature-column {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }

  a.c-btn.c-btn-gradient.mr-2 {
    margin-left: 12px;
  }

  #pointer3 {
    margin-bottom: 1vw;
  }
}

@media only screen and (max-width: 1200px) {
  .iframe-container iframe {
    max-height: 314px;
  }

  .container .banner-wrapper .banner-content h2 {
    line-height: 1.3;
  }
}

@media only screen and (max-width: 991px) {
  .iframe-container {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-banner > .container {
    order: 2;
  }

  .slick-slider {
    overflow-x: hidden;
  }

  .switch label {
    font-size: 14px;
    margin-right: 6px;
  }

  .contact-wrapper .d-flex {
    flex-direction: column;
  }

  .contact-wrapper .cld-contact-info {
    order: 2;
    margin-top: 40px;
  }

  .navbar-nav {
    padding-bottom: 20px;
  }

  .iframe-container {
    margin-top: 30px;
  }

  .iframe-container iframe {
    width: 100%;
    height: 56vw;
    max-height: 305px;
  }

  .features-image {
    width: 80px;
  }
}

@media only screen and (max-width: 540px) {
  .plan-wrapper {
    flex: 0 0 calc(100%);
  }
}

@media only screen and (max-width: 425px) {
  #features .feature-column {
    max-width: 100%;
  }

  .container form .row {
    display: block;
  }
}

@media only screen and (max-width: 380px) {
  .c-btn + .c-btn {
    margin-left: 0;
  }
}

@media only screen and (min-width: 426px) and (max-width: 1023px) {
  #features .feature-column {
    flex: 0 0 50%t;
    width: 50%;
    max-width: 50%;
  }
}

.section--active [data-content-title]:before {
  display: none;
}

/* .new-home {
	height: 100%;
  } */
.banner-wrapper {
  display: flex;
  align-items: center;
  padding-top: 100px;
}

.banner-wrapper .banner-content {
  flex: 0 0 45%;
  max-width: 45%;
  background: transparent;
  color: #212529;
}

.banner-wrapper .banner-content h2 {
  font-size: 3.5rem;
  line-height: 1;
  font-weight: 900;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgb(0, 0, 0);
  max-width: 450px;
  text-transform: none;
}

@media (max-width: 1200px) {
  .banner-wrapper .banner-content h2 {
    font-size: 2rem;
  }
}

.banner-wrapper .banner-content h2 span {
  color: #00b0f5;
}

.banner-wrapper .bannerImg {
  flex: 0 0 60%;
  max-width: 60%;
}

.banner-wrapper .bannerImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navbar-dark .navbar-nav .nav-item {
  margin: 0 15px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #212529;
  font-size: 15px;
  font-weight: 600;
}

h2.slide-up-heading {
  font-size: 40px;
}

span.blue-highlight {
  font-weight: 900;
}

.font-thin {
  font-weight: 900;
  text-transform: none;
}

span.blue-highlight {
  text-transform: none;
}

.singleplan {
  display: block;
}

h2.slide-up {
  text-transform: none;
}

h4.slide-up {
  text-transform: none;
}

.blue-highlight {
  color: #00aeef;
}

.ce-intro-content h2 {
  font-weight: 900;
  text-transform: none;
}

.table--wrapper .table-rows .title p,
.table--wrapper .table-rows .table--info p {
  text-transform: capitalize;
}

.navbar-dark .navbar-nav .nav-item.active .nav-link,
.navbar-dark .navbar-nav .nav-item .nav-link:hover {
  color: #2196f3 !important;
}

.nav-wrapper.is--fixed .navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}

.nav-wrapper .navbar .nav-link {
  text-transform: capitalize;
}

.auth-container .form-group input {
  padding-left: 32px;
}

header.is--fixed .logo--white {
  display: inline-block;
}

header.is--fixed .logo--black {
  display: none;
}

header .logo--white {
  display: none;
}

header:not(.is--fixed) .navbar-dark .navbar-nav .nav-link:focus {
  color: rgb(0, 0, 0);
}

@media (max-width: 992px) {
  .banner-wrapper {
    flex-direction: column;
  }

  .banner-wrapper .banner-content,
  .banner-wrapper .bannerImg {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .navbar-collapse {
    transition: all 0.3s;
    overflow: hidden;
  }

  header:not(.is--fixed) nav.navbar .navbar-toggler i {
    background-color: #000;
  }

  header:not(.is--fixed) .navbar-dark .navbar-nav .nav-link {
    color: #fff;
  }

  .banner-wrapper {
    padding-top: 170px;
  }

  .banner-wrapper .banner-content h2 {
    font-size: 34px;
  }

  a.c-btn.c-btn-gradient.slide-up {
    margin-right: -8px;
  }
}

.feature-column .content-box {
  text-align: left;
}

.feature-column .content-box hr {
  display: none;
}

.feature-column .content-box h4 {
  font-size: 15px;
  margin-top: 16px;
  font-weight: 600;
  text-transform: inherit;
  position: relative;
}

.feature-column .content-box h4:after {
  content: '';
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 1px;
  background-color: #00aeef;
}

.feature-column .content-box img {
  width: 40px;
  margin: 0 !important;
}

.feature-column .content-box:hover:after {
  opacity: 0;
}

.headerLogo {
  display: block;
}

.fix-header {
  display: none;
}

header.is--fixed .headerLogo {
  display: none;
}

header.is--fixed .fix-header {
  display: block;
}

.form-group label {
  letter-spacing: -0.01em;
  text-align: left;
  color: rgb(11, 53, 88);
  font-size: 1.125rem;
  line-height: 2;
  font-weight: 700;
  margin-left: 32px;
  margin-bottom: 8px;
}

.form-group input {
  border-radius: 40px;
  height: 64px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px;
  padding: 0 20px;
}

.login--btn {
  display: flex;
  flex-direction: row-reverse !important;
  align-items: center;
  justify-content: space-between !important;
  margin-top: 20px;
}

.login--btn .c-btn {
  height: 46px;
}

@media (max-width: 1199px) {
  .navbar-dark .navbar-nav .nav-item {
    margin: 0 5px;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: #212529;
    font-size: 0.7rem;
  }
}

.pricing-plans-wrapper .pricing-plan .plan-wrapper {
  padding: 22px;
  background-color: rgb(248, 248, 248);
  border-radius: 40px;
  border: none;
}

.pricing-plans-wrapper .pricing-plan .plan-wrapper .plan-header {
  align-items: flex-start;
}

.pricing-plans-wrapper .pricing-plan .plan-wrapper .plan-header h3 {
  min-height: auto;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgb(11, 53, 88);
  line-height: 2;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 24px;
}

div#navbarSupportedContent a {
  text-transform: none;
}

.section-title h2 {
  font-weight: 900;
  font-size: 40px;
}

.pricing-plans-wrapper .pricing-plan .plan-wrapper .plan-header p {
  letter-spacing: -0.01em;
  text-align: left;
  color: #212529;
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 700;
}

.pricing-plans-wrapper .pricing-plan .plan-wrapper.plan--is-selected {
  border: 1px solid #51a1df;
  border-radius: 0 0 40px 40px;
  background-color: transparent;
}

.pricing-plans-wrapper .pricing-plan .plan-wrapper.plan--is-selected:before {
  left: -1px;
  width: calc(100% + 2px);
  height: 45px;
  font-size: 16px;
  color: #51a1df;
  border: 1px solid #51a1df;
  border-radius: 40px 40px 0 0;
  background-color: rgb(247, 250, 255);
}

.pricing-plans-wrapper .pricing-plan .plan-wrapper.plan--enterprise {
  background-color: rgb(10, 37, 64);
}

.pricing-plans-wrapper .pricing-plan .plan-wrapper.plan--enterprise h3,
.pricing-plans-wrapper .pricing-plan .plan-wrapper.plan--enterprise p {
  color: #ffffff;
}

.section-pricing .ce-intro .ce-intro-content {
  text-align: center;
}

.section-pricing .ce-intro .tagline,
.billing-option {
  justify-content: center;
}

.table--wrapper {
  border: 1px solid rgb(231, 237, 246);
}

.table--wrapper .table-rows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(231, 237, 246);
  border-left: 0;
  border-right: 0;
}

.table--wrapper .table-rows .title {
  flex: 0 0 30%;
  max-width: 30%;
  border-right: 1px solid rgb(231, 237, 246);
  padding: 20px;
}

.table--wrapper .table-rows .table--info {
  flex: 0 0 17.5%;
  max-width: 17.5%;
  text-align: center;
  border-right: 1px solid rgb(231, 237, 246);
  padding: 20px;
}

.table--wrapper .table-rows .table--info:last-child {
  border: none;
}

.table--wrapper .table-rows.header p {
  font-weight: 600 !important;
  color: #00aeef !important;
}

.table--wrapper .table-rows .title p,
.table--wrapper .table-rows .table--info p {
  margin: 0;
  letter-spacing: -0.01em;
  color: rgb(11, 53, 88);
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 400;
}

@media (max-width: 767px) {
  .table--wrapper {
    overflow-x: scroll;
  }

  .table--wrapper .table-rows .title p,
  .table--wrapper .table-rows .table--info p {
    font-size: 0.8rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .table--wrapper .table-rows .table--info p img {
    width: 40%;
  }

  .c-btn + .c-btn {
    margin-right: 15px;
  }

  .table--wrapper .table-rows .title {
    flex: 0 0 60%;
    max-width: 60%;
    padding: 10px;
    min-height: 65px;
    position: relative;
  }

  .table--wrapper .table-rows .table--info {
    flex: 0 0 40%;
    max-width: 40%;
    padding: 10px;
    min-height: 65px;
    position: relative;
    border-bottom: 1px solid rgb(231, 237, 246);
  }

  .table--wrapper {
    border-bottom: none;
  }

  .table--wrapper .table-rows {
    border-bottom: none;
  }

  .table--wrapper .table-rows .table--info:last-child {
    border-bottom: 1px solid rgb(231, 237, 246);
    border-right: none;
  }

  .ce-intro-content h2 {
    font-size: 34px;
  }

  .section-title h2 {
    font-size: 34px;
  }

  .ce-intro-content blockquote {
    margin: 20px 0px 20px 0px;
  }

  .lottie-player {
    width: 84%;
  }
}

body .c-btn {
  min-width: 160px;
  display: inline-flex;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  padding: 11px 28px;
  text-transform: uppercase;
}

.form-group label.error {
  background-color: transparent;
  margin: 0;
  top: auto;
  bottom: -5px;
}
