body{
    /* color: #FFFFFF !important;
    background-color: #212529 !important; */
}

.customHeight {
    min-height: 100vh;
}

.button {
    color: #212529 !important;
    background: linear-gradient(to right, #00b0f5 0%, #009edc 100%);
}
.signup-content {
    margin-top: 20px;
}
